import React, { useEffect } from "react"
import { useAuth } from "react-use-auth"

const Auth0CallbackPage = () => {
  const { handleAuthentication } = useAuth()
  useEffect(() => {
    handleAuthentication()
  }, [handleAuthentication])

  return (
      <p>Redirecting to labs...</p>
  )
}

export default Auth0CallbackPage